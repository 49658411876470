* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  user-select: none;
  touch-action: manipulation;
}
svg,
img {
  max-width: 100%;
  vertical-align: bottom;
}
